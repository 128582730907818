* {
  box-sizing: border-box;
}


@import "abstracts/variables";
@import "abstracts/animations";
@import "base/print";
@import "base/reset";
@import "base/typography";
@import "base/utilities";
@import "base/grid";
@import "layout/colors";
@import "layout/page";
@import "layout/header";
@import "layout/footer";
@import "layout/popup";
@import "components/bannervideo";
@import "components/sectionTitle";
@import "components/button";
@import "components/concept";
@import "components/lineIcon";
@import "components/cardcity";
@import "components/bandeauDiagonals";
@import "components/icoText";
@import "components/bannerOverlay";
@import "components/calltoaction";
@import "components/cardBlog";
@import "components/newsletter";
@import "components/bannerPresentation";
@import "components/banner2blocs";
@import "components/faq";
@import "components/brochure";
@import "components/testimonial";
@import "components/bannerCity";
@import "components/progressBar";
@import "components/bannerImage";
@import "components/programme";
@import "components/timeline";
@import "components/cardMedia";
@import "components/pagination";
@import "components/article";
@import "components/filterMedia";
@import "components/gallery";
@import "components/cardPartenaire";
@import "email-obfuscator";
@import "cloudcannon";