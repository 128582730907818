$yellow: #f8f13d;
$red: darken(#e40f21, 8%);
$darkred: darken(#4c0000, 8%);
$green: darken(#37bb70, 10%);
// $blue:#19a0d8;
$blue: #172c47;
$lightblue: #6fe9fc;
$darkblue: #172c47;
$grey: #1c2427;

$title_font: "RadioGrotesk";


$siteWideSM: 520px;
$siteWideMD: 658px;
$siteWideLG: 850px;
$siteWideXL: 1200px;


.black{ color: black; }
.black-20{ color: rgba(black, 0.2); }
.black-50{ color: rgba(black, 0.5); }
.black-80{ color: rgba(black, 0.8); }
.white{color: $lightblue;}
.blue{color: $blue !important;}

strong.white > a {
	color: $lightblue;
}