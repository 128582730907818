.FaqItem{
	display: inline-block;
	margin-bottom: 1rem;
}

.FaqItem.large{
	width: 100%;
	.FaqItem__question, .FaqItem__answer{max-width: 100%;}
}

.FaqItem__question{
	position: relative;
	display: inline-flex;
	// vertical-align: middle;
    align-items: flex-start;
    background-color: white;
    padding: 8px 16px;
    color: black;
    font-size: 1.3rem;
    width: 100%;
    max-width: 450px;
    cursor: pointer;
}
.FaqItem__question:after{
	content: "▼";
	position: absolute;
	top: 50%;right: 10px;
	transform: translateY(-50%);
	transition: 0.3s;
	font-size: 1rem;
}
.FaqItem__question .icon{
	width: 16px;
	min-width: 16px;
	margin: 4px 12px 0 0;
}
.FaqItem__question .letter{
	color: $blue;
	font-family: $title_font;
	font-size: 2rem;
    margin-right: 20px;
    line-height: 1rem;
}	
.FaqItem__answer{
	display: block;
	background-color: white;
	color: black;
    font-size: 1rem;
    width: 100%;
    max-width: 450px;

    padding: 0;
    height: 0;
    overflow: hidden;
}

.FaqItem__answer img {
	max-width: 500px;
	max-height: 500px;
}

.FaqItem.open .FaqItem__question:after{
	transform: rotate(180deg);
	transition: 0.3s;
}
.FaqItem.alwaysopen .FaqItem__answer,
.FaqItem.open .FaqItem__answer{
		padding: 12px 16px;
    height: auto;
}
.FaqItem.alwaysopen .FaqItem__question:after{
	display: none;
}

.FaqSection{
	padding: 4rem 15px;
	background-size: cover;
	background-position: center;
	margin-bottom: 10px;
    padding-bottom: 300px;
}

@media(max-width: 1199px){
	.FaqItem__question{
	    padding: 1rem 2rem 1rem 1rem;
	    font-size: 1rem;
	}
	.FaqItem.open .FaqItem__answer{
		padding: 0.7rem;
	}
	.FaqItem {
      margin-bottom: 0.5rem;
    }
    .FaqItem__answer {
	  font-size: 0.9rem;
    }
}