.Page{
  display: flex;
}

.Page__title{
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2em;
  line-height: 1.3;
}
.Page__content{
  min-width: 60%;
  max-width: 100%;
  padding: 3rem;
}
.Page__pictures{
  min-width: 40%;
  @include mobile{
    display: none;
  }
}
.Page__pictures .pic-item{
  background-size: cover;
  background-position: center center;
  width: 100%;

  @include xl{
    height: 400px;
  }

  @include lg-only{
    height: 300px !important;
  }
  @include md-only{
    height: 200px;
  }
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 800px) {
  h2 {
    font-size: 1.5rem;
  }
}

.full-height {
	height: 100%;
}

.bigger-text {
	font-size: 1.125em;
		    @media (max-width: 800px) {
        font-size: 1.075em;
    }
}

.min-height-50 {
	min-height: 50px;
}
.min-height-60 {
	min-height: 60px;
}
.min-height-80 {
	min-height: 80px;
}
.min-height-100 {
	min-height: 100px;
}
.min-height-120 {
	min-height: 120px;
}
.min-height-150 {
	min-height: 150px;
}
.min-height-200 {
	min-height: 200px;
}

@media (max-width: 800px) {
  .hidden-xs {
    display: none;
  }

  .button.button--small-xs {
    padding: 0px 1rem;
    min-width: unset;
    font-size: 0.95rem;
    margin: 0 0 1rem 0;
  }
}