.popup-overlay {
	position: fixed;
	z-index: 1000;
	background: rgba(33,33,33,0.88);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	.popup-container {
		border: 2px solid $blue;
		width: 500px;
		max-width: 96%;
		margin: 12% auto 0;
		padding: 20px;
		background: white;
		border-radius: 10px;
		box-shadow: 1px 1px 5px rgba(33,33,33,0.75);
	}
	.popup-message {
	}
	.popup-actions {
		margin-top: 20px;
		text-align: center;
		.button {
			margin-bottom : 0;	
		}
	}
}