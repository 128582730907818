.Gallery{
	margin-bottom: 2rem;
}

.Gallery__item{
	display: block;
	height: 300px;
	width: 100%;
	background-size: cover;
	background-position: center center;
}

.Gallery .owl-nav{
	position: absolute;
	top: -98px;	left: 0; right: 0;
	text-align: center;
}

.Gallery .owl-nav .owl-prev, .Gallery .owl-nav .owl-next{
	position: relative;
	font-size: 4rem !important;
}

.Gallery .owl-nav .owl-prev{ left: MAX(-45%, -420px); }
.Gallery .owl-nav .owl-next{ right: MAX(-45%, -420px); }