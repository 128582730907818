.Brochure{
	position: relative;
	background-color: $yellow;
	margin-bottom: 10px;
	padding: 4rem 15px;
	min-height: 600px;
}
.Brochure.blue{background-color: $blue;}

.Brochure__image{
	position: absolute;
	display: block;
	right: 0;
	top: -130px;
	width: 800px;
	max-width: 100%;


	@media (max-width: 992px){top: 0;width: 533px;}
	@include mobile{
		position: relative; top: unset; right: -15px;margin-left: auto;
	}
}

.Screenshot__image,
.Screenshot__image1,
.Screenshot__image2,
.Background__image {
	position: absolute;
	display: block;
	z-index: 11;
	max-width: 100%;
	top: -10px;
	right: 80px;
	max-height: 400px;
	border-radius: 10px;
	box-shadow: 1px 2px 5px rgba(33,33,33,0.75);
	@media (max-width: 992px) {
	  max-height: 300px;
	  margin-right: 30%;
	  margin-top: 30px;
      right: 50px;
    }
}
.Screenshot__image2 {
	z-index: 10;
	right: -80px;
	top: 30px;
}
.Screenshot__image {
	height: 250px;
	z-index: 11;
	right: 100px;
	top: 30px;
}
.Background__image {
	height: 250px;
	z-index: 10;
	right: -150px;
	top: 0px;
	border-radius: 0px;
}

.Brochure input:not([type='checkbox']){
	display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 2rem;
    border: 2px solid transparent;
    margin: 0 5px 10px 5px;
    width: 100%;
    max-width: 400px;
}

.Brochure input:not([type='checkbox']):focus{
	border-color: $blue;
}