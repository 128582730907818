.Pagination{
	position: relative;
	display: block;
	text-align: center;
	padding: 4rem 1rem;
}

.Pagination__number{
	font-family: $title_font;
	font-size: 2rem;
	color: black;
	margin: 0 5px ;
}
.Pagination__prev .arrow{
	transform: rotate(180deg);
}
.Pagination__number:hover, .Pagination__number.active, .Pagination__prev:hover, .Pagination__next:hover{
	color: $blue;
}

.Pagination__next, .Pagination__prev{
    position: relative;
    top: -10px;
	color: rgba(black, 0.5);
	margin: 0 20px;
	.arrow{
		width: 15px;
	}
}
