@font-face {
  font-family: 'RadioGrotesk';
  src: url('/assets/fonts/RadioGrotesk-Bold.otf') format('opentype');      
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/assets/fonts/HelveticaNeue.woff') format('woff');      
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 17px;
  line-height: 1.4em;
}

input:focus, button:focus{outline: 0;}

.footnote {
  font-size: 0.93rem;
  opacity: 0.9;
  margin-top: 5px;
}