.BannerOverlay{
	position: relative;
	min-height: 140px;
	background-size: cover;
	background-position: center center;
	margin-bottom: 10px;
	color: #fff;
	padding: 4rem 1rem;

	display: flex;
	align-items: center;
}

.BannerOverlay .overlay{
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	background: linear-gradient(127deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,0) 100%);
}


.BannerOverlay__content {
	position: relative;
}

@media (max-width: 1600px){
	.BannerOverlay{
		min-height: 0;
		padding: 2rem 1rem;
	}
}