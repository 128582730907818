.BannerVideo{
	margin-bottom: 10px;
}
.BannerVideo__left {
    justify-content: center;
    color: $lightblue;
    padding: 2rem 3rem;
    padding: calc(max(2em, 20% - 300px)) 3rem;
    background-image: url(/assets/images/accueil/HOME-allicons.svg);
    background-position: right 20px bottom 20px;
    background-repeat: no-repeat;
    background-size: 150px auto;
    @media (max-width: 1199px){
      background-size: 100px auto;
      padding: 2rem 3rem 3rem;
	}
}
/*
.BannerVideo__left .content{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
*/
.BannerVideo__right{
	position: relative;
	margin: auto;
}
.BannerVideo__right .overlay{
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	background-size: cover;
	background-position: center center;
	cursor: pointer;
}