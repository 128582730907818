.float-message-for-content-editor {
  display: none;
  position: absolute;
  z-index: 100;
  background: rgba(252, 252, 139, 0.95);
  padding: 10px 15px;
  border: 1px solid rgba(0,0,0,0.5);
  box-shadow: 1px 1px 5px 3px rgba(0,0,0,0.2);
  top: 30px;
  left: 20px;
  text-align: center;
  cursor: default;
}

.float-message-for-content-editor p {
    font-size: 0.9rem;
}

.float-message-for-content-editor a {
    background: grey;
    border: 1px solid black;
    padding: 5px 15px;
    border-radius: 3px;
    box-shadow: 1px 1px 5px 3px rgba(0,0,0,0.2);
    color: white;
    text-decoration: none;
    margin: 0 20px;
}
  
.cms-editor-active .float-message-for-content-editor {
  display: block;
}