.CallToAction{
	position: relative;
	min-height: 400px;
	margin-bottom: 10px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 2rem;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}


.CallToAction .icon{
	color: #fff;
	height: 25px;
	margin-bottom: 1rem;
}
.CallToAction .content{
    max-width: 400px;
}