.CardsCities .col-12{
	padding-right: 5px;
    padding-left: 5px;
}
.CardCity{
	width: 100%;
	margin-bottom: 10px;
	text-align: center;
	min-width: 260px;
	padding: 0 6px;
}

.CardCity__map{
    max-width: 35%;	
    margin-bottom: 2rem;
}
.CardCity__date{
	color: rgba($lightblue, 0.7);
    font-size: 1.1rem;
}
.CardCity__city{
	min-height: 6rem;
    margin-bottom: 1.4rem;	
}
.CardCity__title{
	color: $lightblue;
	text-transform: uppercase;
	font-family: $title_font;
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 1.2rem;	
}

.CardCity__city .CardCity__title {
    margin-bottom: 1rem;
}
.CardCity__partner{
	color: rgba($lightblue, 0.7);
	font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.2;
}
.CardCity__location {
    font-size: 1.1rem;
    line-height: 1.1;
    font-weight: 500;
	color: rgba($lightblue, 0.6);
}

.CardCity__caption{
	color: rgba(255, 255, 255, 0.4);
}


.CardCity__footer .button{
	padding: 7px 2rem;
	min-width: unset;
	margin-right: 10px;
	white-space: nowrap;

	&.green{color: $green;}
	&.red{color: $red;}
	&.yellow{color: $yellow;}
	&.blue{color: $blue;}
}
.CardCity__footer .more{
	color: $lightblue;
	white-space: nowrap;
}

.bgyellow .CardCity__title,
.bgyellow .CardCity__date,
.bgyellow .CardCity__location,
.bgyellow .CardCity__footer .more {
		color: $darkblue;
}