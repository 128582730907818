.BannerPresentation{
	padding: 4rem 15px;
	text-align: center;
	margin-bottom: 10px;

	background-size: cover;
	background-position: center center;
}

.BannerPresentation__content{
	max-width: 900px;
	margin: 0 auto;
}

.BannerPresentation .sectionTitle .sub{
	color: black;
	font-size: 1.3rem;
	letter-spacing: 2px;
}

.BannerPresentation__numbers{
	font-weight: 600;
	margin-bottom: 4rem;
}
.BannerPresentation__numbers .big{
	color: white;
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.3;
}