.progressBar{
	padding: 15px 0;
	// margin-bottom: 1.5rem;
	text-align: center;
}

.progressBar--green{color: $green;}
.progressBar--yellow{color: $yellow;}
.progressBar--blue{color: $blue;}
.progressBar--red{color: $red;}

.progressBar__label{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1.5rem;
}
.progressBar__label .big{
	font-size: 2rem;
	color: black;
	font-family: $title_font;
	margin-right: 15px;

	@include mobile{
		font-size: 1.5rem;
	}
}
.progressBar__label .sub{
	font-weight: 600;
	color: currentColor;
}

.progressBar__bar{
	display: inline-block;
	background-color: #f1f1f1;
	height: 30px;
	width: 100%;
	border-radius: 30px;
	overflow: hidden;
}

.progressBar__bar .inner{
	background-color: currentColor;
	height: 100%;
	transition: 1s;
}